<template>
  <div>
    <!-- 2.为Echarts准备一个Dom -->
    <el-row>
      <el-col :span="16">
         <div id="main" style="width: 1000px;height:600px;"></div>
      </el-col>
      <el-col :span="6">
         <p>欢迎登录</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
}
</script>

<style>
div{
   margin: 0;
   padding: 0;
}
</style>
